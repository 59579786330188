import { Application } from "@hotwired/stimulus";

const application = Application.start();
const offer = "testament";
// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;
window.addEventListener("load", function () {
  window.dataLayer.push({
    event: "page_loaded",
    type: offer,
  });
});

export { application };
