import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static isEditingMode = false;
  connect() {
    const regex = /maglev\/preview\/.*/;
    const isOnPreview = regex.test(window.location.pathname);
    isOnPreview ? (this.isEditingMode = true) : (this.isEditingMode = false);
    if (this.isEditingMode) {
      this.open();
    }
  }

  submitForm(event) {
    event.preventDefault();
    fetch(event.target.action, {
      method: "POST",
      body: new FormData(event.target),
    })
      .then((response) => {
        if (response.ok) {
          this.open();
          window.dataLayer.push({
            event: "download_request",
            type: "testament",
          });
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {})
      .catch((error) => {
        console.error({ error });
      });
  }

  open() {
    const modal = document.getElementById("staticModal");
    modal.setAttribute("style", "display: block;");
    modal.classList.remove("hidden");
    modal.classList.add("block");
  }

  close(event) {
    event.preventDefault();
    const modal = document.getElementById("staticModal");
    const subscription_form = document.getElementById("subscription_form");
    const form = document.getElementById("registration-form");
    form.reset();
    modal.removeAttribute("style");
    modal.classList.remove("block");
    modal.classList.add("hidden");
    subscription_form.classList.add("hidden");
  }
}
