import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("Controller connected!");
    this.scrollBehaviour();
  }

  disconnect(){
    const scrollContainer = document.getElementById("horizontallyScrollable");
    // ✅ Remove event listeners from Element
    // we cloned the node using the Node.cloneNode() method and passed the result to the Element.replaceWith() method.
    scrollContainer.replaceWith(scrollContainer.cloneNode(true));
  }

  scrollBehaviour() {
    var isDragging = false;
    var startX, scrollLeft;
    const scrollContainer = document.getElementById("horizontallyScrollable");
    const rightButton = document.getElementById("right");
    const leftButton = document.getElementById("left");
    const cards = document.getElementsByClassName("testimony");
    updateIconColor();

    scrollContainer.addEventListener("wheel", (event) => {
      event.preventDefault();
      const scrollFactor = 0.7;
      scrollContainer.scrollLeft += event.deltaY * scrollFactor;
      updateIconColor();
    });

    scrollContainer.addEventListener("mousedown",  (e) => {
      isDragging = true;
      startX = e.clientX;
      scrollLeft = scrollContainer.scrollLeft;
      updateIconColor();
    });

    scrollContainer.addEventListener("mousemove", (e)=> {
      if (!isDragging) return;
      var x = e.clientX - startX;
      scrollContainer.scrollLeft = scrollLeft - x;
      updateIconColor();
    });

    scrollContainer.addEventListener("mouseup",  ()=> {
      isDragging = false;
    });

    rightButton.addEventListener("mousedown",  (e) => {
      const prevPos = scrollContainer.scrollLeft;
      const currPos = getPosition(scrollContainer.scrollLeft + (scrollContainer.scrollWidth / cards.length));
      scroll(prevPos, currPos);

      updateIconColor();
    });

    leftButton.addEventListener("mousedown", (e) => {
      const prevPos = scrollContainer.scrollLeft;
      const currPos = getPosition(scrollContainer.scrollLeft - (scrollContainer.scrollWidth / cards.length));
      scroll(prevPos, currPos);

      updateIconColor();
    });

  // make the color of the arrows gray or black based on the scroll
  function updateIconColor() {
    if (scrollContainer.scrollLeft < 15) {
      leftButton.classList.add("text-gray-middle");
    } else {
      leftButton.classList.remove("text-gray-middle");
    }

    if (
      scrollContainer.scrollLeft + scrollContainer.clientWidth ==
      scrollContainer.scrollWidth
    ) {
      rightButton.classList.add("text-gray-middle");
    } else {
      rightButton.classList.remove("text-gray-middle");
    }
  }

  // reset position to snapping position
  function getPosition(pos){
    pos += cards[0].clientWidth;
    var total_width = scrollContainer.scrollWidth;
    var elements = cards.length;
    var width = total_width / elements;
    var position = width * Math.floor(pos / width);
    return position;
  }

  // animate transition between two positions
  function scroll(prevPos, currPos){
    const scrollAmount = currPos - prevPos;

    const startPosition = scrollContainer.scrollLeft;
    const targetPosition = startPosition + scrollAmount;

    const duration = 500;
    const easingFunction = (t) => t;

    let startTime = null;

    function animateScroll(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const newPosition = startPosition + (targetPosition - startPosition) * easingFunction(progress);
      updateIconColor();

      scrollContainer.scroll(newPosition, 0);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    }

    requestAnimationFrame(animateScroll);
    }
  }
}
