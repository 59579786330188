import { Controller } from "@hotwired/stimulus";

export default class RegistrationForm extends Controller {
  static targets = [
    "register",
    "fname",
    "lname",
    "phone",
    "email",
    "terms",
    "modal",
  ];
  registerButton = document.getElementById("mc-embedded-subscribe");
  validForm = {
    fname: false,
    lname: false,
    phone: false,
    email: false,
    terms: false,
  };
  ENDPOINT = "/order_leads";

  connect() {}

  active(button) {
    button.disabled = false;
    button.classList.remove("btn-disabled");
    button.classList.add("btn-yellow");
  }

  inactive(button) {
    button.disabled = true;
    button.classList.remove("btn-yellow");
    button.classList.add("btn-disabled");
  }

  validateForm() {
    const valid = Object.values(this.validForm).every(
      (value) => value !== false
    );
    return valid
      ? this.active(this.registerButton)
      : this.inactive(this.registerButton);
  }

  showErrorField(input, value) {
    if (!value) {
      input.style.border = "solid 2px #e40041";
    } else {
      input.style = "";
    }
  }

  validateFirstName() {
    const fnameTarget = this.fnameTarget.value.trim();
    !fnameTarget
      ? (this.validForm.fname = false)
      : (this.validForm.fname = true);
    this.showErrorField(this.fnameTarget, this.validForm.fname);
    this.validateForm();
  }

  validateLastName() {
    const lnameTarget = this.lnameTarget.value.trim();
    !lnameTarget
      ? (this.validForm.lname = false)
      : (this.validForm.lname = true);
    this.showErrorField(this.lnameTarget, this.validForm.lname);
    this.validateForm();
  }

  validatePhoneNumber() {
    const phoneTarget = this.phoneTarget.value.trim();
    String(phoneTarget).length > 13 || String(phoneTarget).length < 9
      ? (this.validForm.phone = false)
      : (this.validForm.phone = true);
    this.showErrorField(this.phoneTarget, this.validForm.phone);
    this.validateForm();
  }

  validateEmail() {
    const email = this.emailTarget.value.trim();
    const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    !valid ? (this.validForm.email = false) : (this.validForm.email = true);
    this.showErrorField(this.emailTarget, this.validForm.email);
    this.validateForm();
  }

  validateTerms() {
    const terms = this.termsTarget.checked;
    !terms ? (this.validForm.terms = false) : (this.validForm.terms = true);
    this.validateForm();
  }

  open() {
    const modal = document.getElementById("staticModal");
    modal.setAttribute("style", "display: block;");
    modal.classList.remove("hidden");
    modal.classList.add("block");
  }

  close(event) {
    event.preventDefault();
    const modal = document.getElementById("staticModal");
    const subscription_form = document.getElementById("subscription_form");
    modal.removeAttribute("style");
    modal.classList.remove("block");
    modal.classList.add("hidden");
    subscription_form.classList.add("hidden");
  }

  submitForm(event) {
    event.preventDefault();
    const form = document.getElementById("registration-form");
    fetch(this.ENDPOINT, {
      method: "POST",
      body: new FormData(form),
    })
      .then((response) => {
        if (response.ok) {
          this.open();
          return response.json();
        }
        return Promise.reject(response);
      })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }
}
