import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Controller connected!");
    window.addEventListener("resize", this.handleWindowResize);
    this.update_hero_02_height();
  }

  disconnect() {
    window.addEventListener("resize", null);
  }

  handleWindowResize() {
    this.update_hero_02_height();
  }

  // update height based on content
  update_hero_02_height() {
    const content = document.getElementById("content");
    const parent = document.getElementById("parent");
    const image = document.getElementById("image");
    parent.style.height =
      Math.max(image.clientHeight, content.clientHeight) + "px";
  }
}
