import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subscribe", "email", "terms"];
  subscribeButtonTarget = document.getElementById("subscribeButton");
  validForm = {
    email: false,
    terms: false,
  };

  connect() {}

  active(button) {
    this.subscribeButtonTarget.removeAttribute("disabled");
    button.classList.remove("btn-disabled");
    button.classList.add("btn-yellow");
  }

  inactive(button) {
    this.subscribeButtonTarget.setAttribute("disabled", "");
    button.classList.remove("btn-yellow");
    button.classList.add("btn-disabled");
  }

  validateForm() {
    const valid = Object.values(this.validForm).every(
      (value) => value !== false
    );
    return valid
      ? this.active(this.subscribeButtonTarget)
      : this.inactive(this.subscribeButtonTarget);
  }

  validateEmail() {
    const email = this.emailTarget.value;
    const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    !valid ? (this.validForm.email = false) : (this.validForm.email = true);
    this.validateForm();
  }

  validateTerms() {
    const terms = this.termsTarget.checked;
    !terms ? (this.validForm.terms = false) : (this.validForm.terms = true);
    this.validateForm();
  }
}
